<template>
  <div>
    <div class="container">
      <div class="handle-box">
        <el-input
          v-model="query.name"
          placeholder="姓名"
          class="handle-input mr10"
          clearable
          @keyup.enter="handleSearch"
        ></el-input>
        <el-input
          v-model="query.mobile"
          :maxlength="11"
          placeholder="联系电话"
          class="handle-input mr10"
          clearable
          @keyup.enter="handleSearch"
        ></el-input>
        <el-input
          v-model="query.id_card"
          placeholder="身份证"
          class="handle-input mr10"
          clearable
          @keyup.enter="handleSearch"
        ></el-input>
        <el-select
          clearable
          v-model="query.sex"
          placeholder="性别"
          class="handle-select mr10"
        >
          <el-option key="1" label="男" value="男"></el-option>
          <el-option key="2" label="女" value="女"></el-option>
        </el-select>
        <el-select
          v-model="query.level"
          placeholder="会员等级"
          class="handle-select mr10"
          clearable
        >
          <el-option
            v-for="item in level_list"
            :key="item.id"
            :value="item.level"
            :label="item.level_name"
          ></el-option>
        </el-select>
        <el-select
          v-model="query.vip"
          placeholder="会员卡等级"
          class="handle-select mr10"
          clearable
        >
          <el-option
            v-for="item in card_list"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          ></el-option>
        </el-select>
        <div style="margin: 10px 0">
          <label class="el-form-item__label">
            <el-date-picker
              v-model="date"
              @change="handleDate"
              type="daterange"
              range-separator="至"
              start-placeholder="创建开始日期"
              end-placeholder="创建结束日期"
            >
            </el-date-picker>
          </label>
        </div>
        <el-button
          type="primary"
          icon="el-icon-search"
          class="ml10"
          @click="handleSearch"
          >搜索</el-button
        >
        <el-button
          type="info"
          icon="el-icon-copy-document"
          class="ml10"
          @click="handleReset"
          >重置</el-button
        >
      </div>
      <!-- @row-click="handleEdit" -->
      <el-table
        :data="tableData"
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
      >
        <el-table-column
          align="center"
          type="index"
          width="50"
          label="序号"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="number"
          label="路印id"
        ></el-table-column>
        <el-table-column align="center" prop="image" label="头像">
          <template #default="scope">
            <el-image
              style="width: 75px; height: 100px"
              :src="
                scope.row.image || 'https://www.looin.cn/assets/img/asd.jpg'
              "
              :preview-src-list="[
                scope.row.image || 'https://www.looin.cn/assets/img/asd.jpg',
              ]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="name" label="姓名">
          <template #default="scope">
            <div class="user_name" @click.stop="nameClick(scope.row.id)">
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="level_name" label="身份">
          <template #default="scope">
            <el-tag
              v-if="scope.row.status === 0 && scope.row.level_name != ''"
              >{{ scope.row.level_name }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column align="center" label="会员卡等级">
          <template #default="scope">
            <el-tag type="success" v-if="scope.row.vip_name">{{
              scope.row.vip_name
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="sex"
          label="性别"
          width="50"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="age"
          label="年龄"
          width="50"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="id_card"
          width="150"
          label="身份证"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="mobile"
          label="联系电话"
          width="120"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="create_time"
          width="200"
          label="创建时间"
        ></el-table-column>
        <el-table-column label="操作" width="180">
          <template #default="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              @click.stop="handleEdit(scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next, sizes"
          :current-page="query.page"
          :page-size="query.limt"
          :page-sizes="[10, 20, 50]"
          :total="pageTotal"
          @current-change="handlePageChange"
          @size-change="handleSizeChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { vipRecord, vipLevel, getCard } from "../../api/index";
export default {
  name: "memberList",
  data() {
    return {
      query: {
        sex: "",
        name: "",
        mobile: "",
        id_card: "",
        level: "",
        create_begin: "",
        create_end: "",
        vip: "",
        page: 1,
        limt: 10,
      },
      date: "",
      pageTotal: 0,
      tableData: [],
      level_list: [],
      card_list: [],
    };
  },
  created() {
    this.getData();
    this.onVipLevel();
    this.onGetCard();
  },
  methods: {
    // 获取数据
    getData() {
      vipRecord(this.query).then((res) => {
        this.tableData = res.list;
        this.pageTotal = res.total;
      });
    },
    onVipLevel() {
      vipLevel().then((res) => {
        this.level_list = res;
      });
    },
    // 获取会员卡等级
    onGetCard() {
      getCard().then((res) => {
        this.card_list = res;
      });
    },

    // 选择时间
    handleDate() {
      if (this.date) {
        const d = new Date(this.date[0]);
        const d2 = new Date(this.date[1]);
        this.query.create_begin =
          d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
        this.query.create_end =
          d2.getFullYear() + "-" + (d2.getMonth() + 1) + "-" + d2.getDate();
      } else {
        this.query.create_begin = "";
        this.query.create_end = "";
      }
    },

    // 触发搜索按钮
    handleSearch() {
      this.query.page = 1;
      this.getData();
    },

    // 重置
    handleReset() {
      this.date = "";
      this.query = {
        sex: "",
        name: "",
        mobile: "",
        id_card: "",
        level: "",
        create_begin: "",
        create_end: "",
        vip: "",
        page: 1,
        limt: 10,
      };
      this.getData();
    },

    // 编辑操作
    handleEdit(row) {
      this.$router.push({ path: "/memberInfo", query: { id: row.id } });
    },

    // 分页导航
    handlePageChange(val) {
      this.query.page = val;
      this.getData();
    },
    handleSizeChange(val) {
      this.query.page = 1;
      this.query.limt = val;
      this.getData();
    },
    // 点击名字跳转
    nameClick(id) {
      this.$router.push({
        path: "/memberInfo",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.ml10 {
  margin-left: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.user_name {
  font-size: 14px;
  color: #66b1ff;
  cursor: pointer;
  font-weight: 500;
  display: inline-block;
  border-bottom: 1px solid transparent;
}
</style>
